import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0,0,0)',
    },
    secondary: {
      main: 'rgb(34 193 195 / 72%)',
    },
    background: {
      default: 'rgb(245,246,250)',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Inter',
    ].join(','),
  },
  
})

export default theme