/* eslint-disable max-len */
import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, Theme, Typography } from '@mui/material'
import {
  FormatListNumberedRtl,
  CreditCard,
  AccountBalance,
} from '@mui/icons-material'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import CustomButton from '../../components/CustomButton'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
}), { name: 'Payment' })

const Payment = (): JSX.Element => {
  const classes = useStyles()

  const [errorMsg, setErrorMsg] = useState('')

  const {
    setStage,
    creditCard,
    setCreditCard,
    creditCardValidity,
    setCreditCardValidity,
    creditCardBackNumbers,
    setCreditCardBackNumbers,
    accountNumber,
    setAccountNumber,
    branchNumber,
    setBranchNumber,
    bankNumber,
    setBankNumber,
  } = useContext(loanContext)

  const isValid = (): boolean => {
    if ((!creditCard && !creditCardValidity && !creditCardBackNumbers)
      || ((creditCard || creditCardValidity || creditCardBackNumbers) && (!creditCard || !creditCardValidity || !creditCardBackNumbers))
      || ((accountNumber || branchNumber || bankNumber) && (!accountNumber || !branchNumber || !bankNumber))) {
      setErrorMsg('יש מלא את כל פרטי התשלום')

      return false
    }

    setErrorMsg('')

    return true
  }

  const handleClickContinue = (): void => {
    if (isValid()) {
      setStage((prev) => prev + 1)
    }
  }

  return (
    <div className={`${classes.root} rtl`}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography variant="h5">
          אנא השלימו את פרטי התשלום
        </Typography>
      </div>
      <form className={classes.form}>
        <FormControl sx={{ display: 'flex', flex: 4, textAlign: 'right' }}>
          <TextField
            label='מספר כרטיס'
            value={creditCard}
            onChange={({ target }) => setCreditCard(target.value)}
            id="credit-card"
            margin="normal"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreditCard />
                </InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="תוקף"
              inputFormat='mm/yy'
              value={creditCardValidity}
              onChange={(newValue: any) => {
                setCreditCardValidity(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <TextField
            label='3 ספרות בגב הכרטיס'
            value={creditCardBackNumbers}
            onChange={({ target }) => setCreditCardBackNumbers(target.value)}
            id="credit-card-back-numbers"
            margin="normal"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormatListNumberedRtl />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </form>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography variant="h5">
          או הכניסו פרטי בנק
        </Typography>
      </div>
      <form className={classes.form}>
        <FormControl sx={{ display: 'flex', flex: 4, textAlign: 'right' }}>
          <div style={{ display: 'flex' }}>
            <TextField
              label='מספר חשבון'
              value={accountNumber}
              onChange={({ target }) => setAccountNumber(target.value)}
              id="bank-account"
              margin="normal"
              sx={{ flex: 2, paddingLeft: 1 }}
              type='text'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountBalance />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='סניף'
              value={branchNumber}
              onChange={({ target }) => setBranchNumber(target.value)}
              id="credit-card-back-numbers"
              margin="normal"
              sx={{ flex: 1 }}
              type='text'
            />
          </div>
          <FormControl>
            <InputLabel id="select-bank-label">בנק</InputLabel>
            <Select
              labelId="select-bank-label"
              id="select-bank"
              value={bankNumber}
              label="בנק"
              onChange={({ target }) => setBankNumber(target.value)}
            >
              <MenuItem value={9}>בנק הדואר</MenuItem>
              <MenuItem value={10}>בנק לאומי</MenuItem>
              <MenuItem value={11}>בנק דיסקונט</MenuItem>
              <MenuItem value={12}>בנק הפועלים</MenuItem>
              <MenuItem value={13}>בנק אגוד</MenuItem>
              <MenuItem value={14}>בנק אוצר החייל</MenuItem>
              <MenuItem value={17}>בנק מרכנתיל</MenuItem>
              <MenuItem value={20}>בנק מזרחי טפחות</MenuItem>
              <MenuItem value={31}>בנק בינלאומי</MenuItem>
              <MenuItem value={54}>בנק ירושלים</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText sx={{ color: 'red', alignSelf: 'flex-start', paddingTop: 3 }}>{errorMsg}</FormHelperText>
        </FormControl>
      </form>
      <CustomButton onClick={handleClickContinue}>
        המשך
      </CustomButton>
    </div >
  )
}

export default Payment
