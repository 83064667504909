import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgress, FormControl, FormHelperText, Theme, Typography } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CustomButton from '../../components/CustomButton'
import { sendOtpViaSms } from '../../functions/auth0'
import otpMessageImage from '../../assets/icons/otp-message.jpg'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: spacing(3),
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    paddingTop: spacing(3),
    flexDirection: 'column',
    width: '80%',
    alignSelf: 'center',
  },
  title: {
    letterSpacing: 0.2,
    fontFamily: 'monospace'
  },
  subTitle: {
    padding: spacing(),
    fontFamily: 'monospace',
    color: 'grey',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorMessageContainer: {
    alignSelf: 'center'
  },
}), { name: 'Login' })

const Login = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    phone,
    setPhone,
  } = useContext(loanContext)

  const validateForm = (): boolean => {
    let msg = ''
    let valid = true

    if (!phone) {
      msg = 'אנא הכנס מספר טלפון'
      valid = false
    }

    setErrorMessage(msg)

    return valid
  }

  const handleClickContinue = async (): Promise<void> => {
    if (validateForm()) {
      try {
        setIsLoading(true)
        await sendOtpViaSms(phone)
        // Example on returned response from above await function
        // {
        //   Id: "618159ef78d57a7db21afc83"
        //   phoneNumber: "+972584201996"
        //   phoneVerified: false
        //   requestLanguage: "en-US,en;q=0.9"
        // }
        setStage((prev) => prev + 1)
      } catch (error: any) { // eslint-disable-line
        if (error.code === 'bad.phone_number') {
          console.error('Developer: the phone number needs an international code (+972)')
        } else if (error.code === 'bad.connection') {
          console.error('Developer: add this application to Auth0 passwordless SMS permitted-applications')
        } else {
          console.error(error)
        }
      }
      setIsLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img src={otpMessageImage} alt='img' width='200' height='200' />
      </div>
      <div className={classes.formContainer}>
        <Typography sx={{ fontSize: 22 }} className={classes.title}>
          הכנס מספר טלפון
        </Typography>
        <Typography sx={{ fontSize: 14 }} className={classes.subTitle}>
          ישלח אליך קוד בעל 6 ספרות לצורך עימות
        </Typography>
        <FormControl sx={{ padding: 2 }}>
          <PhoneInput
            country={'il'}
            value={phone}
            onChange={(phone) => setPhone(`+${phone}`)}
            inputStyle={{ width: '250px' }}
            inputProps={{
              required: true,
              autoFocus: true,
            }}
          />
          <div className={classes.errorMessageContainer}>
            <FormHelperText error sx={{ height: 5 }}>
              {errorMessage}
            </FormHelperText>
          </div>
        </FormControl>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <CustomButton onClick={handleClickContinue} sx={{ width: '100%' }}>
            המשך
          </CustomButton>
        )}
      </div>
    </div >
  )
}

export default Login
