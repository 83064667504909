import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import './App.css'
// import Loading from './components/Loading'
import Settings from './views/Settings/Settings'
import Logs from './views/Logs/Logs'
import ErrorPage from './components/Error'
import LoanJourney from './views/Loan/LoanJourney'
import LoanCalculator from './views/LoanCalculator/LoanCalculator'

const MainRouter = (): JSX.Element => {
  const { /* isLoading, */ error } = useAuth0()

  if (error) {
    return <ErrorPage error={error} />
  }

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <Router>
      <div style={{ height: '100%' }}>
        {/* <NavBar /> */}
        <div style={{ height: '100%' }}>
          <Switch>
            <Route path="/loan" exact component={LoanJourney} />
            <Route path="/loan-calculator" exact component={LoanCalculator} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/logs" component={Logs} />
            <Route>
              <Redirect
                to={window.location.search.indexOf('?otp=true') !== -1 ? '/loan?otp=true' : '/loan-calculator'}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default MainRouter