/* eslint-disable max-len */
import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { FormHelperText, InputAdornment, TextField, Theme, Typography } from '@mui/material'
import AccountBox from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import NationalIdIcon from '@mui/icons-material/Fingerprint'
import CustomButton from '../../components/CustomButton'
import isValidNationalID from '../../utils/is-valid-national-id'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    paddingBottom: spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
}), { name: 'PersonalDetails' })

const PersonalDetails = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    errorMessage,
    setErrorMessage,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    nationalId,
    setNationalId,
  } = useContext(loanContext)

  const validateForm = (): boolean => {
    let msg = ''
    let valid = true

    if (!firstName || !lastName || !email || !nationalId) {
      msg = 'אנא מלא את כל השדות'
      valid = false
    }

    if (!isValidNationalID(nationalId)) {
      msg = 'תעודת הזהות לא תקינה'
      valid = false
    }

    setErrorMessage(msg)

    return valid
  }

  const handleClickContinue = (): void => {
    if (validateForm()) {
      setStage((prev) => prev + 1)
    }
  }

  return (
    <div className={`${classes.root} rtl`} >
      <Typography variant='h5' className={classes.title}>פרטים אישיים</Typography>
      <form className={classes.form}>
        {/* <FormControl> */}
        <TextField
          required
          value={firstName}
          onChange={(({ target }) => setFirstName(target.value))}
          label='שם פרטי'
          id="first-name"
          margin="normal"
          type='text'
          InputLabelProps={{
            sx: {
              textAlign: 'center'
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountBox />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          value={lastName}
          onChange={(({ target }) => setLastName(target.value))}
          label='שם משפחה'
          id="last-name"
          margin="normal"
          type='text'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountBox />
              </InputAdornment>
            ),
          }}
        />
        <TextField required value={email} onChange={(({ target }) => setEmail(target.value))}
          label='דוא״ל'
          id="email"
          margin="normal"
          type='email'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField required value={nationalId} onChange={(({ target }) => setNationalId(target.value))}
          label='תעודת זהות'
          id="national-id"
          margin="normal"
          type='text'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <NationalIdIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.errorMessageContainer}>
          <FormHelperText error sx={{ height: 5 }}>
            {errorMessage}
          </FormHelperText>
        </div>
        {/* </FormControl> */}
      </form>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomButton onClick={handleClickContinue}>
          המשך
        </CustomButton>
      </div>
    </div>
  )
}

export default PersonalDetails
