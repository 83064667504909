/* eslint-disable max-len */
import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, Theme, Typography } from '@mui/material'
import AccountBox from '@mui/icons-material/AccountBox'
// import DateIcon from '@mui/icons-material/DateRange'
import LocationCity from '@mui/icons-material/LocationCity'
import Streetview from '@mui/icons-material/LocationOn'
import CustomButton from '../../components/CustomButton'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    paddingBottom: spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
}), { name: 'AdditionalDetails' })

const AdditionalDetails = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    errorMessage,
    setErrorMessage,
    fullNameEnglish,
    setFullNameEnglish,
    birthday,
    setBirthday,
    gender,
    setGender,
    familyStatus,
    setFamilyStatus,
    city,
    setCity,
    address,
    setAddress,
  } = useContext(loanContext)

  const validateForm = (): boolean => {
    let msg = ''
    let valid = true

    if (!birthday || !fullNameEnglish || !gender || !familyStatus || !city || !address) {
      msg = 'אנא מלא את כל השדות'
      valid = false
    }

    setErrorMessage(msg)

    return valid
  }

  const handleClickContinue = (): void => {
    if (validateForm()) {
      setStage((prev) => prev + 1)
    }
  }

  return (
    <div className={`${classes.root} rtl`}>
      <Typography variant='h5' className={classes.title}>פרטים אישיים</Typography>
      <form className={classes.form}>
        <FormControl sx={{ display: 'flex', flex: 2, justifyContent: 'space-evenly' }}>
          <TextField
            label='שם מלא באנגלית'
            value={fullNameEnglish}
            onChange={({ target }) => setFullNameEnglish(target.value)}
            id="full-name-english"
            margin="dense"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBox />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='תאריך לידה'
            value={birthday}
            onChange={({ target }) => setBirthday(target.value)}
            id="birthday"
            InputLabelProps={{ shrink: true }}
            margin="dense"
            type='date'
          />
          <TextField
            label='עיר'
            value={city}
            onChange={({ target }) => setCity(target.value)}
            id="city"
            margin="dense"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Streetview />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='רחוב'
            value={address}
            onChange={({ target }) => setAddress(target.value)}
            id="address"
            margin="dense"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationCity />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <div style={{ display: 'flex', flex: 1 }}>
          <FormControl fullWidth margin='normal' style={{ paddingLeft: 4, paddingRight: 4 }}>
            <InputLabel id="gender-label">מין</InputLabel>
            <Select
              labelId="gender-label"
              value={gender}
              label="מין"
              onChange={({ target }) => setGender(target.value)}
            >
              <MenuItem value='male'>זכר</MenuItem>
              <MenuItem value='female'>נקבה</MenuItem>
              <MenuItem value='other'>אחר</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin='normal' style={{ paddingLeft: 4, paddingRight: 4 }}>
            <InputLabel id="family-status-label">מצב משפחתי</InputLabel>
            <Select
              labelId="family-status-label"
              value={familyStatus}
              label="מצב משפחתי"
              onChange={({ target }) => setFamilyStatus(target.value)}
            >
              <MenuItem value='signal'>רווק</MenuItem>
              <MenuItem value='married'>נשוי</MenuItem>
              <MenuItem value='divorcee'>גרוש</MenuItem>
              <MenuItem value='widower'>אלמן</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={classes.errorMessageContainer}>
          <FormHelperText error>
            {errorMessage}
          </FormHelperText>
        </div>
      </form>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomButton onClick={handleClickContinue}>
          המשך
        </CustomButton>
      </div>
    </div>
  )
}

export default AdditionalDetails
