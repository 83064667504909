import { Button } from '@mui/material'
import { styled } from '@mui/system'

const CustomButton = styled(Button)({
  backgroundColor: 'rgb(44 159 161 / 72%)',
  color: 'white',
  fontWeight: 'bold',
  height: 50,
  fontSize: 18,
  
  '&:hover': {
    backgroundColor: 'rgb(47 133 135 / 92%)',
  },
})

export default CustomButton
