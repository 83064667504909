/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme, Typography } from '@mui/material'
import CustomButton from '../../components/CustomButton'
import Plan from '../../components/generic/Plan'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
}), { name: 'ChoosePlan' })

const ChoosePlan = (): JSX.Element => {
  const classes = useStyles()

  const {
    setOpen,
    loanPlan,
    setLoanPlan,
    plans,
  } = useContext(loanContext)

  const handleChangePlan = useCallback(({ target }: SelectChangeEvent): void => {
    const chosenPlan = plans.find((plan) => plan.period === target.value)

    if (chosenPlan) {
      setLoanPlan(chosenPlan)
    }
  }, [plans, setLoanPlan])

  const handleClickContinue = useCallback((): void => {
    localStorage.removeItem('loan_params')

    setOpen(false)
  }, [setOpen])

  return (
    <div className={`${classes.root} rtl`}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 10, }}>
        <Typography variant="h5" fontFamily="fantasy">
          ההלוואה שלך אושרה!
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '80%', alignSelf: 'center' }}>
        <FormControl fullWidth>
          <InputLabel id="payment-track-select-label">בחירת מסלול התשלומים</InputLabel>
          <Select
            labelId="payment-track-select-label"
            id="payment-track-select"
            value={loanPlan.period}
            label="בחירת מסלול התשלומים"
            onChange={handleChangePlan}
          >
            {
              plans.map((plan) => <MenuItem value={plan.period} key={plan.title}> {plan.title} - {plan.period}</MenuItem>)
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 4 }}>
        <Plan plan={loanPlan} />
      </div>
      <CustomButton onClick={handleClickContinue}>
        סיום
      </CustomButton>
    </div>
  )
}

export default ChoosePlan
