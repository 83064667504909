import { Button, Divider, Slider, TextField, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { useHistory } from 'react-router-dom'
import Rate from '../../components/generic/Rate'
import Errors from '../../components/Errors'
import lendingRates from '../../data/lending-rates'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'sans-serif',
  },
  container: {
    width: '80%',
    maxWidth: 950,
    height: 550,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0fbfb',
    padding: spacing(2),
    borderRadius: 20,
    boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 40%)',
  },
  calculatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    padding: spacing(3),
    placeContent: 'space-around',
  },
  lendingDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    padding: spacing(1),
  },
  rateRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    margin: spacing(1.5),
  },
  continueBtn: {
    backgroundColor: 'rgb(44 159 161 / 72%) !important',
    color: 'white !important',
    fontWeight: 'bold',
    width: 250,
  },
}), { name: 'LoanCalculator' })


const CustomSlider = styled(Slider)({
  color: 'rgba(34,193,195,1)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 45,
    height: 45,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

const LoanCalculator = (): JSX.Element => {
  const classes = useStyles()

  const minLendingAmount = 1000
  const maxLendingAmount = 50000
  const minLendingPeriod = 12
  const maxLendingPeriod = 60
  const numOfDecibelsRate = 3
  const numOfDecibelsAmount = 0
  const history = useHistory()

  const [lendingAmount, setLendingAmount] = useState<number>(50000)
  const [lendingPeriod, setLendingPeriod] = useState<number>(24)
  const [errors, setErrors] = useState<Array<string>>([])

  const verifyAmount = useCallback((num = 0): Array<string> | boolean => {
    let valid = true
    const youCannotTakeLoan = 'אין באפשרותך לקחת הלוואה של '
    const invalidLoanError = `${youCannotTakeLoan} ${num} ₪`

    for (let i = 0; i < errors.length; i++) {
      const err = errors[i]

      if (err.indexOf(youCannotTakeLoan) !== -1) {
        const copyOfErrors = [...errors]

        copyOfErrors.splice(i, 1)
        setErrors(copyOfErrors)
      }
    }

    if (num < minLendingAmount || num > maxLendingAmount) {
      setErrors((prev) => {
        return [...prev, invalidLoanError]
      })

      valid = false
    }

    return valid
  }, [errors])

  const getApyWithLendingPeriodChange = (): number => {
    const rateObj = lendingRates.find((rate) => rate.minMonths <= lendingPeriod && rate.maxMonths > lendingPeriod)

    if (!rateObj) return 0

    return rateObj.apy
  }

  const [apy, setApy] = useState(getApyWithLendingPeriodChange())

  useEffect(() => {
    verifyAmount(lendingAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lendingAmount])

  const getInterestRate = (period = 'year', asNumberType = false): string | number => {
    let num = 0

    if (period === 'day' || period === 'daily') {
      num = (apy / 365)
    }
    else if (period === 'month' || period === 'monthly') {
      num = (apy / 365) * (365 / 12)
    }
    else if (period === 'year' || period === 'yearly') {
      num = apy
    }

    const fixedNum = num.toFixed(numOfDecibelsRate)

    return asNumberType ? Number(fixedNum) : fixedNum
  }

  const getInterestAmount = (period = 'year', asNumberType = false): number | string => {
    const numOfYears = lendingPeriod / 12
    const num = (lendingAmount * ((Number(getInterestRate(period)) * numOfYears) / 100)).toFixed(numOfDecibelsAmount)

    return asNumberType ? Number(num) : num
  }

  const handleSliderChangeLendingAmount = (event: Event, newValue: number): void => setLendingAmount(newValue)


  const handleSliderChangeLendingPeriod = (event: Event, newValue: number): void => {
    setLendingPeriod(newValue)

    setApy(getApyWithLendingPeriodChange())
  }

  const handleClickContinue = (): void => {
    setErrors([])

    if (verifyAmount(lendingAmount)) {
      const params = {
        amount: lendingAmount,
        term: lendingPeriod,
      }

      localStorage.setItem('loan_params', JSON.stringify(params))

      history.push('/loan')
    }
  }

  return (
    <div className={`${classes.root} rtl`} >
      <div className={classes.container}>
        <Typography variant='h4' align='center'>מחשבון הלוואה</Typography>
        <div style={{ display: 'flex', flex: 1, }}>
          <div className={classes.calculatorContainer}>
            <div>
              <div style={{ display: 'flex' }}>
                <Typography sx={{ flex: 2, fontSize: 16 }}>אני רוצה לקחת הלוואה של:</Typography>
                <TextField
                  size='small'
                  variant="outlined"
                  value={`${lendingAmount} ₪`}
                  sx={{ fontSize: 14, fontWeight: 'bold', width: '30%' }}
                  onChange={({ target }) => setLendingAmount(Number(target.value.replace('₪', '')))}
                />
              </div>
              <CustomSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                value={lendingAmount}
                onChange={handleSliderChangeLendingAmount}
                min={minLendingAmount}
                max={maxLendingAmount}
                step={500}
                marks={[
                  { value: minLendingAmount, label: `${minLendingAmount} ₪` },
                  { value: maxLendingAmount, label: `${maxLendingAmount} ₪` }
                ]}
              />
            </div>
            <div>
              <div style={{ display: 'flex' }}>
                <Typography sx={{ flex: 2, fontSize: 16 }}>הלוואה לתקופה של:</Typography>
                <TextField
                  size='small'
                  variant="outlined"
                  sx={{ width: '30%' }}
                  value={`${lendingPeriod / 12} שנים`}
                />

              </div>
              <CustomSlider
                aria-label="pretto slider"
                value={lendingPeriod}
                onChange={handleSliderChangeLendingPeriod}
                min={minLendingPeriod}
                max={maxLendingPeriod}
                step={6}
                marks={[{ value: 12, label: 'שנה' }, { value: 60, label: '5 שנים' }]}
              />
            </div>
            <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'column' }}>
              <Errors errors={errors} />
              <Button className={classes.continueBtn} onClick={handleClickContinue} >
                המשך עם הבקשה
                <KeyboardArrowLeft />
              </Button>
            </div>
          </div>
          <div style={{ padding: 20 }}>
            <Divider orientation="vertical" sx={{ borderColor: 'green' }} />
          </div>
          <div className={classes.lendingDetailsContainer}>
            <div className={classes.rateRow}>
              <Rate title='סכום ריבית' subTitle='(ILS ליום)' value={`${getInterestAmount('day')}₪`} />
              <Rate title='אחוזי ריבית' subTitle='(% ליום)' value={`${getInterestRate('day')}%`} />
            </div>
            <div className={classes.rateRow}>
              <Rate title='סכום ריבית' subTitle='(ILS לחודש)' value={`${getInterestAmount('month')}₪`} />
              <Rate title='אחוזי ריבית' subTitle='(% לחודש)' value={`${getInterestRate('month')}%`} />
            </div>
            <div className={classes.rateRow}>
              <Rate title='סכום ריבית' subTitle='(ILS לשנה)' value={`${getInterestAmount('year')}₪`} />
              <Rate title='אחוזי ריבית' subTitle='(% לשנה)' value={`${getInterestRate('year')}%`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanCalculator
